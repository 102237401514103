.modalFormat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 24px;
  min-width: 50vw !important;
}
.modalFormat-referencias {
  min-width: 63vw;
  max-height: 90vh;
  min-height: 90vh;
  overflow: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.search-div {
  border: 1px solid #cecece;
  border-radius: 4px;
  display: flex;
}
.cached-icon {
  margin-top: 10px !important;
  float: left !important;
  cursor: pointer;
  width: 2em !important;
  font-size: 2em !important;
}

.button-farol-ref {
  width: 25vw;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  padding: 2px 16px;
  display: flex;
}

.input-farol-ref {
  width: 20vw;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  padding: 16px 16px;
  display: flex;
}

.search-icon-ref {
  margin: auto;
  margin-left: 1%;
  color: #616161;
  cursor: pointer;
  font-size: 23px;
}

.top-box-ref {
  margin: 10px 0px;
  justify-content: space-between;
}

.close-modal {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  padding-bottom: 10px;
}

.title-box-2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.select-title {
  font-weight: 500;
  font-size: 0.875em;
}

.closeIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title-modal {
  font-family: "Inter", sans-serif;
  text-transform: lowercase;
  font-weight: 600;
}

.title-modal::first-letter {
  text-transform: uppercase;
}

.texto-modal {
  font-family: "Inter", sans-serif !important;
}

.box-select-ord-modal {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  float: right;
}

.ref-title {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap !important;
  align-content: flex-start !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  /* padding-bottom: 12px; */
}

.checkbox-todas-referencias {
  margin: 20px 0;
  display: flex;
  width: 100%;
}

.btn-tornar-visivel {
  border: 0;
  padding: 8px 16px;
  height: 40px;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ebebeb;
}
.btn-float-right {
  float: right !important;
  position: relative !important;
}
.divider-top-modal {
  margin: 0px 0px 30px 0px;
  border-bottom: #cecece solid 0.5px;
  width: 100%;
}

.todos-ofertados {
  width: 100%;
  height: auto;
  padding: 16px 8px;
}

.texto-todas-referencias::before {
  content: "Selecitonar todas as referencias";
}

@media screen and (max-width: 959px) {
  .texto-todas-referencias::before {
    content: "Todas";
  }

  .title-box-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .search-div {
    min-width: 100% !important;
  }

  .input-farol-ref {
    min-width: 60vw;
  }

  .ref-title {
    width: 100% !important;
    display: flex;
    align-content: flex-start !important;
    font-size: 3.2vw !important;
    font-weight: 600 !important;
    padding-bottom: 12px;
    padding-top: 10px;
    word-break: break-word !important;
  }
  .box-select-ord-modal {
    width: 100%;
    display: flex;
    /* justify-content: flex-end; */
    justify-content: space-between;
  }
  .select-title {
    padding-left: 1vw;
  }
  .modalFormat {
    width: 85vw !important;
  }
}

@media screen and (min-width: 959px) {
  .search-div {
    width: 70%;
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .modalFormat-referencias {
    max-width: 75vw !important;
  }
}
