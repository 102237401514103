body {
  background-color: #e5e5e5 !important;
}

td {
  padding: 0px !important;
}
.td:first-of-type {
  min-width: initial !important;
  padding-left: 0px !important;
}
.scroll {
  overflow-x: scroll;
  height: 50vh;
  width: 100vw;
}
.select-deflator-marca {
  min-width: 10vw !important;
}

.box-valores-deflatores {
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.texto-total-deflatores {
  width: 10vw;
  font-size: 14px;
  height: 56px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.input-upload {
  width: 100px;
  margin-right: 15px;
  /* margin-top: 20px; */
}

.icone-upload-deflatores {
  font-size: 38px !important;
  color: #757575 !important;
}

.btn-exportar-deflatores {
  height: 56px !important;
  margin-top: initial !important;
  min-width: 8vw !important;
  justify-content: center !important;
}

.espaco-texto-card {
  margin: 12px 0;
}

.maincard {
  display: flex;
  flex-direction: row;
  padding: 32px;
  background-color: #ebebeb !important;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  height: 100vh;
  width: 100%;
  text-align: left important!;
}

.cardtotalstorage {
  flex-direction: column;
  text-align: left !important;
  background: #ffffff;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  max-height: 468px;
  margin-left: 32px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 50px;
  width: 80%;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left !important;
}

.line-1 {
  height: 1px;
  background: #cecece;
}

.circlered {
  background-color: #c52518;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circlegreen {
  background-color: #148a6a;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circleRed {
  margin: 0 10px 0 0;
  background-color: #c52518;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circleGreen {
  margin: 0 10px 0 0;
  background-color: #148a6a;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.h6formatoffer {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #272424;
  font-family: "Inter", sans-serif !important;
}

.valor-ofertado {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #616161 !important;
  font-family: "Inter", sans-serif !important;
}

.infoformatoffer {
  margin-left: -4.5vh;
  margin-top: -2.3vh;
}

.option {
  background-color: #fff;
  padding: 10px;
}

.container {
  padding: 16px 24px !important;
}

.titulo-principal-gestao {
  color: #272424;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vw;
  line-height: 3vw;
  font-feature-settings: "salt" on;
  display: flex;
  align-items: baseline;
}

.sub-titulo-principal {
  color: #616161;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 121.5%;
}

.titulo-grafico {
  color: #272424;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  margin-top: -2%;
}

.box-atributos2 {
  margin-top: 10% !important;
  padding-left: 10%;
}

.scrollmenu {
  background-color: #333;
  display: flex;
  flex-direction: row;
  overflow: auto !important;
  white-space: nowrap;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.graph-sku {
  display: flex;
  padding-top: 3vw;
}

.div-sku {
  display: flex;
  flex-direction: column;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  line-height: 5.5vw;
  letter-spacing: 0.00938em;
}

.box-individual-market {
  flex-wrap: nowrap !important;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 24px 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.28);
  background-color: #fff;
  border-radius: 8px;
}

.titulo-box-market {
  display: flex;
  width: 50%;
  gap: 2%;
  align-items: center;
  padding-bottom: 5%;
}

.titulo-market {
  display: flex;
  padding-right: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #616161;
}

.titulo-receita-market {
  display: flex;
  font-size: 18px;
  font-weight: 600;
}

.box-skus-market {
  width: fit-content;
  display: flex;
  align-items: center;
}

.box-principal-market {
  display: flex;
  gap: 2%;
}
.p-l-0 {
  padding-left: 0 !important;
}

@media screen and (min-width: 1200px) {
  .graph-sku {
    display: flex;
    padding-top: 0px;
  }
}
@media screen and (max-width: 959px) {
  .sub-titulo-principal {
    margin-top: 10px;
  }

  .maincard {
    flex-direction: column !important;
  }

  .maincard {
    margin-left: 0 !important;
    margin-bottom: 32px !important;
  }

  .maincard:last-child {
    margin-bottom: 0 !important;
  }

  .maincard section.card button {
    font-size: 70% !important;
  }
  /* 
  .container {
    padding: 0 !important;
  } */

  .titulo-principal-gestao {
    font-size: 4.5vw;
    line-height: 4vw;
  }

  .titulo-grafico {
    font-size: 5.5vw !important;
    font-weight: 500 !important;
    padding-bottom: 3vh;
  }

  .sub-titulo-1 {
    font-size: 4vw !important;
  }

  .sub-titulo-2 {
    font-size: 4vw !important;
  }

  .box-individual {
    padding: 0px 0px !important;
  }

  .box-atributos2 {
    padding-left: 0px !important;
  }

  .h6formatoffer {
    font-weight: 600;
    font-size: 4vw;
    line-height: 19px;
    color: #272424;
    padding-bottom: 0px;
    font-family: "Inter", sans-serif !important;
  }

  .valor-ofertado {
    font-weight: 500 !important;
    font-size: 4vw !important;
    line-height: 19px !important;
    color: #616161 !important;
    font-family: "Inter", sans-serif !important;
  }

  .circlered {
    background-color: #c52518;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }

  .circlegreen {
    background-color: #148a6a;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }

  .div-sku {
    display: flex;
    flex-direction: column;
    padding: 3vw 3vw;
  }

  .graph-sku {
    display: flex;
    margin: 0vw;
    padding-top: 0vw;
  }

  .sub-titulo-principal {
    width: 240px;
    line-height: 121.5%;
  }
  .box-principal-market {
    display: flex;
    flex-direction: column;
    gap: 10%;
  }

  .titulo-market {
    font-size: 4vw !important;
    font-weight: 500 !important;
    color: #616161;
  }

  .titulo-receita-market {
    font-size: 4vw;
    font-weight: 600;
  }

  .box-skus-market {
    line-height: 9vw;
  }
}
