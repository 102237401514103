.tabela-responsiva {
  overflow-x: scroll;
  overflow-y: scroll;
  min-height: 250px;
  height: 80vh;
}

.sort {
  margin: 10px;
  border: none;
  background-color: #dcc6b7;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* ::-webkit-scrollbar-track {
  background-color: #fff;
} */

::-webkit-scrollbar {
  width: 0px;
  /*   background: #ddc7b7; */
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*  background: #ddc7b7; */
  border-radius: 5px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  height: 50px;
}

thead {
  background-color: #dcc6b7;
}

th {
  text-align: center;
  padding: 14px;
  display: table-cell;
  vertical-align: inherit;
  min-width: 170px;
  background-color: #dcc6b7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* th:first-of-type {
  min-width: 0px !important;
} */

td {
  text-align: center;
  border-bottom: 1px solid #caac96 !important;
  display: table-cell;
  vertical-align: inherit;
  min-width: 170px;
  padding: 12px 12px;
  font-weight: 500;
  font-size: 14px;
}

td:first-of-type {
  min-width: 0px !important;
}

tfoot {
  background-color: #dcc6b7;
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
  text-align: end;
}

.borda-horizontal {
  border-left: 2px solid #caac96 !important;
  height: 30px;
  align-items: center;
  display: grid;
  justify-items: center;
  margin-left: -15px;
  padding: 10px;
  letter-spacing: 0.1px;
  color: #272424;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.borda-horizontal:nth-child(even) {
  border-left: inherit !important;
}

.header-tabela {
  background-color: #dcc6b7;
}

.paginacao {
  margin-left: calc(100vw - 60%);
}

tr:nth-child(even) {
  background-color: #f4eeea;
}

tr:nth-child(odd) {
  background-color: #ffff;
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the label as desired */
.custom-checkbox {
  cursor: pointer;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

/* Style the checkmark using a pseudo-element */
.custom-checkbox .checkmark:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  background-color: #ffff;
  border-radius: 2px;
  margin-right: 8px;
  vertical-align: middle;
  text-align: center;
  color: #000;
  font-weight: bold;
  line-height: 16px;
}

/* Style the checkmark when the checkbox is checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark:before {
  content: "✓";
  background-color: #fff;
}

.texto-laranja {
  text-transform: uppercase;
  color: orange;
}
.texto-laranja-2 {
  text-transform: uppercase;
  color: #978126;
}

.texto-vermelho {
  text-transform: uppercase;
  color: rgb(197, 37, 24);
}

.texto-preto {
  text-transform: uppercase;
  color: #000;
}

.texto-verde {
  text-transform: uppercase;
  color: #148a6a;
}

.borda-primeira {
  border-left: 2px solid #caac96 !important;
  height: 30px;
  align-items: center;
  display: grid;
  justify-items: center;
  padding: 10px;
  letter-spacing: 0.1px;
  color: #272424;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.white-space-no {
  white-space: nowrap;
}

.valor-estoque {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-header {
  justify-content: inherit;
  padding: 0px;
}

@media screen and (max-width: 959px) {
  .tabela-responsiva {
    height: 70vh;
  }

  .referencia-numero {
    font-size: 10px;
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #dcc6b7;
    padding: 0px;
    font-size: 14px;
  }

  td {
    padding: 3px 3px;
  }

  th:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: #dcc6b7;
    z-index: 2;
  }

  .coluna-referencia {
    display: flex;
  }

  td:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 1;
  }

  .referencia-coluna {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10000;
  }

  .custom-checkbox .checkmark:before {
    width: 12px;
    height: 12px;
    line-height: 12px;
  }

  .valor-estoque {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .borda-primeira {
    height: 18px;
    font-size: 10px;
    padding: 5px 4px;
    min-width: 150px;
  }

  .borda-horizontal {
    height: 18px;
    font-size: 10px;
    padding: 1px 4px;
    min-width: 150px;
  }
}
